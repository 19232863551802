<template>
  <div id="bill-split-container" class="container">
    <h1>Bill Split Calculator</h1>
    <!-- <p>This is a bill splitting calculator with source code on <a href="">GitHub</a></p> -->
    <p>
      This app helps split the cost for meals. Enter the total cost of the meal
      after tax and tip, and the paying parties, and then continue following
      instructions to assign items to different people. It is capable of assigning any number
      of items to any one person and also of even splitting an item between any number of people.
    </p>
    <p>
      This is not yet capable of dealing with multiple paying parties or splitting
      an item unevenly (but reach out if this would be desired functionality and I can add it)
    </p>
    <p>
      Note: This page shall ideally soon be improved. This was done in the course
      of an evening, but shall soon be glorious when I decide to touch
      it up some other fine evening.
    </p>
    <h2>The Calculator</h2>
    <!-- Total and names -->
    <form v-if="page === 'introInfo'" @submit.prevent="submitIntroInfo">
      <label class="form-label">Total cost (including tax and tip)</label>
      <input required type="number" v-model="total" step="0.01" placeholder="XX.YY" name="total" class="form-control">
      <label class="form-label">Who are the paying parties</label>
      <input required type="text" v-model="names" placeholder="Mary, Steve, ..." name="names" class="form-control">
      <button type="submit" class="btn btn-primary mt-3">Submit</button>
    </form>
    <!-- Itemizing -->
    <form v-if="page === 'items'" @submit.prevent="submitItems" class="mb-3">
      <!-- Item -->
      <div v-for="item in items" :key="item.id" class="mb-4">
        <div class="d-flex flex-row flex-wrap justify-content-between mb-3">
          <div class="third-screen pr-4">
            <label class="form-label">Item name (optional)</label>
            <input type="text" :value="item.name" @change="event => item.name = event.target.value" :name="'name' + item.id" class="form-control item-input">
          </div>
          <div class="third-screen px-2">
            <label class="form-label">Item cost</label>
            <input type="number" step="0.01" v-model="item.cost" :name="'cost' + item.id" class="form-control item-input">
          </div>
          <div class="third-screen pl-4 d-flex flex-column flex-wrap justify-content-between">
            <label>Who ate it (check multiple to split)</label>
            <div>
              <input type="checkbox" @change="selectAll" class="mr-1">
              <label class="form-label">Check all</label>
            </div>
            <div v-for="name in namesList" :key="name">
              <input type="checkbox" v-model="item.owners[name]" class="mr-1">
              <label class="form-label">{{ name }}</label>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-between">
          <div class="half-screen pr-3">
            <button @click.prevent="removeItem(item)" class="btn btn-light" style="width: 100%;">Remove {{ item.name || 'Item' }}</button>
          </div>
          <div class="half-screen pl-3">
            <button @click.prevent="duplicateItem(item)" class="btn btn-light" style="width: 100%;">Duplicate {{ item.name || 'Item' }}</button>
          </div>
        </div>
        <hr>
      </div>
      <span class="mr-3">
        <button class="btn btn-secondary" @click.prevent="goBack('introInfo')">Go Back</button>
      </span>
      <span class="mr-3">
        <button @click.prevent="addItem" class="btn btn-secondary">Add Item</button>
      </span>
      <button type="submit" class="btn btn-primary">Submit Items</button>
    </form>
    <!-- Summary page -->
    <form v-if="page === 'summary'">
      <b>Everyone owes the following</b> (items are listed to make sure you inputted correctly; it will have a different sum than the output):
      <ul>
        <li v-for="person in people" :key="person.name">
          <b>{{ person.name }} owes {{ person.trueDebt }}</b>
          <a :href="venmoLink(person)" target="_blank" rel="noreferrer noopener" class="ml-1">(Request on Venmo)</a>
          [{{ describeItems(person.items) }}]
        </li>
      </ul>
      <span class="mr-3">
        <button class="btn btn-secondary" @click.prevent="goBack('items')">Go Back</button>
      </span>
      <button type="submit" class="btn btn-primary" @click="reset">Reset</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface IState {
  page: string;
  total: number | undefined;
  names: string;
  namesList: string[];
  items: IItem[];
  currentId: number;
  people: IPerson[];
}

interface IItem {
  id: number;
  name: string;
  cost: number | undefined;
  owners: { [key: string]: boolean }; // list of name to boolean: { 'joe': true, 'mama': false}
}

interface IPerson {
  name: string;
  items?: IItem[];
  rawDebt: number;
  trueDebt?: number;
}

export default defineComponent({
  data(): IState {
    return {
      page: "introInfo",
      // total: 42,
      total: undefined,
      // names: "alpha, bravo, charlie",
      names: "",
      namesList: [],
      items: [],
      currentId: 0,
      people: [],
    };
  },
  methods: {
    /** Adds a new empty item to items */
    addItem(): void {
      this.items.push({
        id: this.currentId,
        name: "",
        cost: undefined,
        owners: {},
      });
      this.currentId++;
    },
    /** Stores true debts in people objects */
    calculateTrueDebts(): void {
      const totalDebt: number = this.people.reduce((acc, person) => {
        return acc + person.rawDebt;
      }, 0);
      const multiplier = this.total! / totalDebt;
      this.people = this.people.map((person) => {
        return { ...person, trueDebt: this.round(person.rawDebt * multiplier) };
      });
    },
    /** Describes the cost of the item and how many it was split amongst */
    describeItem(item: IItem): string {
      const owners = Object.keys(item.owners).filter(
        (owner) => item.owners[owner]
      );

      // build result string to be of form: "(itemName: cost[ / numSplitters]?)"
      let result = `${item.name || "Unnamed"}: `;
      result += `$${item.cost!}`;
      if (owners.length !== 1) {
        result += ` / ${owners.length}`;
      }
      return `(${result})`;
    },
    /** Describes all the items in a list */
    describeItems(items: IItem[] | undefined): string {
      if (items === undefined) {
        return "";
      }
      const itemDescriptions: string[] = [];
      for (const item of items) {
        itemDescriptions.push(this.describeItem(item));
      }
      return `Subtotal Items: ${itemDescriptions.join(" + ")}`;
    },
    /** Duplicates an item with a reset list of owners */
    duplicateItem(item: IItem): void {
      const dup: IItem = { ...item, owners: {} };
      this.items.push(dup);
    },
    /** Goes back from results page to items page without resetting data */
    goBack(page: string) {
      this.page = page;
    },
    /** Removes the given item from the list of items */
    removeItem(item: IItem): void {
      this.items = this.items.filter((i) => i !== item);
    },
    /** Resets the inputted information */
    reset(): void {
      this.total = undefined;
      this.names = "";
      this.items = [];
      this.page = "introInfo";
    },
    /** Rounds a number to a given number of decimal places */
    round(num: number, decimalPlaces = 2): number {
      const powerOfTen = Math.pow(10, decimalPlaces);
      return Math.round(num * powerOfTen) / powerOfTen;
    },
    /** Selects or deselects all people */
    selectAll(): void {
      // for (const person in this.people) {
      //   person.
      // }
    },
    /** Submits the total cost and names and moves to itemization */
    submitIntroInfo(): void {
      this.namesList = this.names
        .split(/[^a-zA-Z0-9\-_' ]+/)
        .filter((w) => w !== "")
        .map((w) => w.trim());
      this.namesList = [...new Set(this.namesList)];
      if (this.items.length == 0) {
        this.addItem();
      }
      this.page = "items";
    },
    /** Submits itemized costs and calculates everyone's money owed */
    submitItems(): void {
      // initialize people object
      interface IPeople {
        [key: string]: IPerson;
      }
      const peopleObject: IPeople = {};
      this.namesList.forEach((name) => {
        peopleObject[name] = { name, rawDebt: 0 };
      });

      // add cost of each item to people's debts
      this.items.forEach((item: IItem) => {
        const itemOwnerNames = Object.keys(item.owners).filter(
          (name) => item.owners![name]
        );
        const costPerPerson = item.cost! / itemOwnerNames.length;
        itemOwnerNames.forEach((name) => {
          peopleObject[name].rawDebt += costPerPerson;
          // push item onto person's items list
          peopleObject[name].items = peopleObject[name].items ?? [];
          peopleObject[name].items!.push(item);
        });
      });

      // convert people object to people list
      this.people = [];
      Object.keys(peopleObject).forEach((name) =>
        this.people.push({ ...peopleObject[name] })
      );
      this.calculateTrueDebts();

      // show summary page
      this.page = "summary";
    },
    venmoLink(person: IPerson): string {
      const note = encodeURIComponent(
        this.describeItems(person.items)
        // + "\nautogenerated by https://kevincox9600.github.io/bill-splitter"
      );
      return (
        `https://venmo.com/?` +
        `txn=charge&` +
        `audience=private&` +
        `amount=${person.trueDebt}&` +
        `note=${note}`
      );
    },
  },
});
</script>

<style scoped>
#bill-split-container {
  max-width: 600px;
}

.half-screen {
  min-width: 100px;
  width: 50%;
}

.third-screen {
  width: calc(100% / 3);
}

.item-input {
  /* min-width: 100px; */
  /* width: 100%; */
  /* width: 275px; */
  /* max-width: 300px; */
}
</style>