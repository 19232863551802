import TrafficSimulator from "./components/TrafficSimulator.vue";
import Home from "./components/main/Home.vue";
import Doomsday from "./components/doomsday-algorithm/Doomsday.vue";
import BillSplitter from "./components/bill-splitter/BillSplitter.vue";
import MealPlans from "./components/meal-plans/MealPlansOld.vue";
import MealPlans2 from "./components/meal-plans/MealPlans.vue";
// import SecretSite from "./components/secret/SecretSite.vue";
import AslTable from "./components/asl-table/AslTable.vue";
import SeniorSuperlative from "./components/senior-superlative/SeniorSuperlative.vue"
import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/traffic-simulator",
        name: "TrafficSimulator",
        // component: function () { return import('./components/TrafficSimulator.vue'); }
        component: TrafficSimulator,
    },
    {
        path: "/doomsday-trainer",
        name: "Doomsday",
        component: Doomsday
    },
    {
        path: "/bill-splitter",
        name: "BillSplitter",
        component: BillSplitter
    },
    {
        path: "/brown-meal-plans",
        name: "MealPlans",
        component: MealPlans2
    },
    // {
    //     path: "/brown-meal-plans",
    //     name: "MealPlans",
    //     component: MealPlans
    // },
    {
        path: "/asl-table",
        name: "AslTable",
        component: AslTable
    },
    {
        path: "/senior-superlative",
        name: "SeniorSuperlative",
        component: SeniorSuperlative
    },
    // {
    //     path: "/secret",
    //     name: "SecretSite",
    //     component: SecretSite
    // }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// for analytics
declare const window: any; // to appease typescript when accessing window.datalayer
router.beforeEach((to, from) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'virtualPageview',
        'pageUrl': to.fullPath,
        'pageTitle': to.name
    });
    // console.log(to, window);
});

export default router;