/** Rounds a number to a given number of decimal places */
function round(num: number, decimalPlaces = 2): number {
  const powerOfTen = Math.pow(10, decimalPlaces);
  return Math.round(num * powerOfTen) / powerOfTen;
}

/** Converts the number to a string representing a dollar amount */
function formatCost(num: number): string {
  return `$${round(num, 2).toFixed(2)}`;
}

export { formatCost, round }