<!-- choose meal plan and comparison at top (like groceries or cost of thayer meal) -->
<!-- list meal plan stats (credits and flex pts) -->
<!-- savings under different conditions (thayer, cheapest swipes, most expensive, groceries) -->
<!-- slider to choose proportion ayce meals -->
<template>
  <div>
    <table class="table">
      <tbody>
        <tr>
          <td class="table-border-right"><strong>Meal Plan</strong></td>
          <td><strong>Cost per meal</strong></td>
          <td><strong>Ratty ratio<sup>*</sup></strong></td>
          <td class="table-border-right"><strong>Meals per week</strong></td>
          <td><strong>Meal Credits (including guest)</strong></td>
          <td v-if="ignoreFlex"><strong>Cost minus flex points</strong></td>
          <td v-if="!ignoreFlex"><strong>Flex Points</strong></td>
          <td v-if="!ignoreFlex"><strong>Semester Cost</strong></td>
        </tr>
        <tr v-for="plan in plans" :key="plan.name">
          <td class="table-border-right"><strong>{{ plan.name }}</strong></td>
          <td>${{ plan.mealCost }}</td>
          <td>{{ rattyProp(plan) === -1 ? '∞' : round(rattyProp(plan), 0) }}%</td>
          <td class="table-border-right">{{ round(mealsPerDay(plan) * 7, 1) }}</td>
          <td>{{ plan.creds + plan.guest }}</td>
          <td v-if="ignoreFlex">${{ plan.cost - plan.flex }}</td>
          <td v-if="!ignoreFlex">${{ plan.flex }}</td>
          <td v-if="!ignoreFlex">${{ plan.cost }}</td>
        </tr>
      </tbody>
    </table>
    <p><sup>*</sup>Percent of meals at ratty/vdub required to break even and have being on meal plan be worth it</p>
  </div>
</template>

<script lang="ts">
import { round } from "../../helpers";
import { IPlan } from "./types";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import {
  BREAKFAST_AYCE_COST,
  DINNER_AYCE_COST,
  LUNCH_AYCE_COST,
  SWIPE_COST,
  TOTAL_DAYS,
  TOTAL_WEEKS,
} from "./constants";

export default defineComponent({
  props: {
    ignoreFlex: {
      type: Boolean,
      required: false,
      default: true,
    },
    plannedMealsPerWk: {
      type: Number,
      required: false,
      default: 21,
    },
    plans: {
      type: Object as PropType<IPlan[]>,
      required: true,
    },
    rattyCost: {
      type: String,
      required: false,
    }
  },
  methods: {
    calculateMealCost(): void {
      for (const plan of this.plans) {
        const totalMeals = this.totalMeals(plan);
        const cost = this.ignoreFlex ? plan.cost - plan.flex : plan.cost;
        plan.mealCost = this.round(cost / totalMeals, 2);
        plan.totalMeals = totalMeals;
      }
    },
    mealsPerDay(plan: IPlan): number {
      return this.totalMeals(plan) / TOTAL_DAYS;
    },
    rattyProp(plan: IPlan): number {
      const mealsPerWk = this.mealsPerDay(plan) * 7;
      // console.log(plan)
      const cost = this.ignoreFlex ? plan.cost - plan.flex : plan.cost;
      const planCostPerWk = cost / TOTAL_WEEKS;
      // console.log("mpw, costpw", mealsPerWk, planCostPerWk);

      for (let rattyPerWk = 0; rattyPerWk <= 21; rattyPerWk += 0.1) {
        let costOfRatty = 0
        let costs = [];
        if (this.rattyCost === 'avg') {
          let rc = 1/3 * ( DINNER_AYCE_COST + LUNCH_AYCE_COST + BREAKFAST_AYCE_COST );
          costs = [rc, rc, rc];
        } else {
          costs = [DINNER_AYCE_COST, LUNCH_AYCE_COST, BREAKFAST_AYCE_COST];
        }

        let tmpRattyPerWk = rattyPerWk;
        for (let mealCost of costs) {
          costOfRatty += mealCost * Math.max(0, Math.min(7, tmpRattyPerWk));
          tmpRattyPerWk -= 7;
        }

        if (costOfRatty + (mealsPerWk - rattyPerWk) * SWIPE_COST >= planCostPerWk) {
          return rattyPerWk / mealsPerWk * 100;
        }
      }

      return -1;
    },
    round,
    totalMeals(plan: IPlan) {
      let totalMeals: number;

      if (this.ignoreFlex) {
        totalMeals = plan.creds + plan.guest;
      } else {
        totalMeals = plan.creds + plan.flex / SWIPE_COST + plan.guest;
      }
      
      return Math.min(totalMeals, this.plannedMealsPerWk * TOTAL_WEEKS);
    }
  },
  mounted() {
    this.calculateMealCost();
  },
  watch: {
    ignoreFlex() {
      this.calculateMealCost();
    },
    plannedMealsPerWk() {
      this.calculateMealCost();
    },
  }
});
</script>

<style scoped>
.table-border-right {
  border-right: 1px solid #dee2e6;
}

</style>