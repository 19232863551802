<template>
  <!-- <router-link :to="{name: 'Home'}">Home</router-link> -->
  <div>
      <p>Hello</p>
      <canvas id="canvas" width=1000 height=400></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canvas: null,
      ctx: null,
      px: 100,
      py: 200,
      length: 50,
      width: 30,
    };
  },
  computed: {},
  methods: {
    render() {
      const height = 400;
      const width = 1000;

      let car = new Path2D();
      car.rect(this.px, this.py, this.length, this.width);

      // draw intersection
      const streetHalfWidth = 100;
      const centerX = 600;
      let intersection = new Path2D(
        `M0 ${height / 2 - streetHalfWidth} H ${
          centerX - streetHalfWidth
        } V 0` +
          `M 0 ${height / 2 + streetHalfWidth} H ${
            centerX - streetHalfWidth
          } V ${height}` +
          `M ${width} ${height / 2 - streetHalfWidth} H ${
            centerX + streetHalfWidth
          } V 0` +
          `M ${width} ${height / 2 + streetHalfWidth} H ${
            centerX + streetHalfWidth
          } V ${height}`
      );

      // this.ctx.clearRect(width, height);
      this.ctx.fill(car);
      this.ctx.stroke(intersection);
    },
  },
  mounted() {
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");
    setInterval(this.render, 1000);
  },
};
</script>

<style scoped>
#canvas {
  border: 1px solid black;
}
</style>