<template>
  <div id="doomsday-container" class="container text-center d-flex flex-column justify-content-between mt-3">
    <h1>Doomsday Algorithm Trainer</h1>
    <div id="header-div" class="row">
      <button class="col btn btn-outline-primary" type="button" data-toggle="modal" data-target="#settings">
        Settings
      </button>
      <!-- Settings Modal -->
      <div id="settings" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Settings</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <button class="col btn btn-secondary mx-2" @click="setDifficulty('easy')">
                  Easy
                </button>
                <button class="col btn btn-secondary mx-2" @click="setDifficulty('hard')">
                  Hard
                </button>
              </div>
              <p>Start date: {{ dateString(startDate) }}</p>
              <input @input="setDate($event, 'start')" type="date">
              <p>End date: {{ dateString(endDate) }}</p>
              <input @input="setDate($event, 'end')" type="date">
              <p>Time until question disappears: {{ timeUntilQuestionDisappear }}</p>
              <input @input="timeUntilQuestionDisappear = $event.target.value" type="number">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">Score: {{ score }}</div>
    </div>
    <div>Time Elapsed: {{ timeElapsed }}</div>
    <div id="game-div" class="flex-grow-1 d-flex flex-column justify-content-between">
      <!-- Date question -->
      <div id="question-div" class="d-flex flex-column justify-content-center flex-grow-1">
        <p id="question-text" v-if="showDate">{{ dateString(date) }}</p>
      </div>
      <!-- Answer options -->
      <div id="answer-div" class="mb-5">
        <div class="row">
          <button :class="buttonClass(0)"
            class="col mr-2 btn" @click="answer(0)">
            Sunday
          </button>
          <button :class="buttonClass(1)"
            class="col btn" @click="answer(1)">
            Monday
          </button>
          <button :class="buttonClass(2)"
            class="col ml-2 btn" @click="answer(2)">
            Tuesday
          </button>
        </div>
        <div class="row">
          <button :class="buttonClass(3)"
            class="col mr-2 btn" @click="answer(3)">
            Wednesday
          </button>
          <button :class="buttonClass(4)"
            class="col btn" @click="answer(4)">
            Thursday
          </button>
          <button :class="buttonClass(5)"
            class="col ml-2 btn" @click="answer(5)">
            Friday
          </button>
        </div>
        <div class="row">
          <button :class="buttonClass(6)"
            class="col btn" @click="answer(6)">
            Saturday
          </button>
        </div>
        <!-- Explain and next button -->
        <div id="next-button-div" class="row mt-3">
          <button class="btn btn-primary col mr-2">
            Explanation
          </button>
          <button class="btn btn-primary col ml-2" @click="next">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      correct: false,
      guess: 0,
      score: 0,
      answered: false,
      startDate: new Date(2000, 0, 1),
      endDate: new Date(2003, 11, 31),
      date: new Date(),
      timeElapsed: 0,
      timer: null, // the interval for timer
      sDown: false,
      timeUntilQuestionDisappear: 5,
    };
  },
  computed: {
    showDate() {
      return (
        this.timeElapsed < this.timeUntilQuestionDisappear ||
        this.sDown || // show date if s key pressed
        this.answered
      );
    },
  },
  methods: {
    answer(guess) {
      clearInterval(this.timer);
      if (!this.answered) {
        this.guess = guess;
        if (guess == this.date.getDay()) {
          // guess correct
          this.score += Math.floor(
            100 * 0.5 ** Math.floor(this.timeElapsed / 5)
          );
          this.correct = true;
        } else {
          // guess incorrect
          this.correct = false;
          this.score -= 100;
        }
        this.answered = true;
      }
    },
    dateString(date) {
      // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return date.toLocaleString("en-us", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
    randomDate(start, end) {
      var date = new Date(+start + Math.random() * (end - start));
      return date;
    },
    next() {
      this.answered = false;
      this.date = this.randomDate(this.startDate, this.endDate);
      this.startTimer();
    },
    buttonClass(dayOfWeek) {
      const correctClass = "btn-success";
      const incorrectClass = "btn-danger";
      const defaultClass = "btn-outline-primary";
      if (this.answered) {
        // correct answer
        if (this.date.getDay() === dayOfWeek) {
          return correctClass;
        }
        // incorrect answer
        if (this.guess === dayOfWeek) {
          return incorrectClass;
        }
      }
      // no answer yet or not chosen/correct
      return defaultClass;
    },
    setDate(event, time) {
      console.log(event.target.value);
      if (time === "start") {
        this.startDate = new Date(event.target.value);
      } else {
        this.endDate = new Date(event.target.value);
      }
    },
    setDifficulty(difficulty) {
      if (difficulty === "easy") {
        this.startDate = new Date("1999-01-01");
        this.endDate = new Date("2004-01-01");
      } else if (difficulty === "hard") {
        this.startDate = new Date("1700-01-01");
        this.endDate = new Date("2100-01-01");
      }
    },
    startTimer() {
      this.timeElapsed = 0;
      if (this.timer) {
        clearInterval(this.timer);
      }

      const that = this;
      let timerStarted = false;
      const counter = function () {
        if (!timerStarted) {
          timerStarted = true;
          that.timer = setInterval(counter, 1000);
        } else if (that.timeElapsed >= 100) {
          clearInterval(that.timer);
        } else {
          that.timeElapsed++;
        }
      };
      counter();
    },
  },
  created() {
    // generate date between range
    this.date = this.randomDate(this.startDate, this.endDate);

    // console.log(document.getElementById("settings"));
    document.addEventListener("keydown", (e) => {
      const modal = document.getElementById("settings");
      if (!modal.hasClass("show")) {
        if (this.answered && e.key === "Enter") {
          this.next();
        }
        if (e.key in ["0", "1", "2", "3", "4", "5", "6"]) {
          this.answer(parseInt(e.key));
        }
        if (e.key === "s") {
          this.sDown = true;
        }
      }
    });
    document.addEventListener("keyup", (e) => {
      if (e.key === "s") {
        this.sDown = false;
      }
    });

    this.startTimer();
    this.setDifficulty("easy");
  },
};
</script>

<style>
body {
  /* background-color: #990000; */
}
</style>

<style scoped >
#doomsday-container {
  max-width: 600px;
  height: 100vh;
  /* color: white; */
}

#question-text {
  font-size: 50px;
}

.row {
  margin-bottom: 5px;
}

.btn {
  width: 115px;
  margin-bottom: 5px;
}

.correct {
  background-color: green;
}

.incorrect {
  background-color: red;
}
</style>