// // 2022 values
// const SWIPE_COST = 10.30
// // AYCE = all you can eat
// const BREAKFAST_AYCE_COST = 12.80
// const LUNCH_AYCE_COST = 16.75
// const DINNER_AYCE_COST = 19.60

// 2023 values
// AYCE = all you can eat
const SWIPE_COST = 11.25
const BREAKFAST_AYCE_COST = 13.75
const LUNCH_AYCE_COST = 18.00
const DINNER_AYCE_COST = 21.00

const TOTAL_WEEKS = 15
const TOTAL_DAYS = TOTAL_WEEKS * 7

const MEAL_PLANS = [
  {
    yr: "fr",
    name: "Flex 460",
    creds: 230,
    flex: 250,
    guest: 5,
    cost: 3474,
  },
  {
    yr: "fr",
    name: "20 Weekly",
    creds: 15 * 20,
    flex: 100,
    guest: 2,
    cost: 3474,
  },
  {
    yr: "soph",
    name: "Flex 330",
    creds: 165,
    flex: 175,
    guest: 4,
    cost: 3271,
  },
  {
    yr: "soph",
    name: "14 Weekly",
    creds: 15 * 14,
    flex: 75,
    guest: 3,
    cost: 3271,
  },
  {
    yr: "upper",
    name: "Flex 70",
    creds: 35,
    flex: 375,
    guest: 2,
    cost: 1109,
  },
]

// const MEAL_PLANS = [
//         {
//           yr: "fr",
//           name: "Flex 460",
//           creds: 230,
//           flex: 250,
//           guest: 5,
//           cost: 3236,
//         },
//         {
//           yr: "fr",
//           name: "20 Weekly",
//           creds: 20 * TOTAL_WEEKS, // 300
//           flex: 100,
//           guest: 2,
//           cost: 3236,
//         },
//         {
//           yr: "soph",
//           name: "Flex 330",
//           creds: 165,
//           flex: 175,
//           guest: 4,
//           cost: 3047,
//         },
//         {
//           yr: "soph",
//           name: "14 Weekly",
//           creds: 14 * TOTAL_WEEKS, // 210
//           flex: 75,
//           guest: 4,
//           cost: 3047,
//         },
//         {
//           yr: "upper",
//           name: "7 Weekly",
//           creds: 7 * TOTAL_WEEKS, // 105
//           flex: 35,
//           guest: 2,
//           cost: 2506,
//         },
//         {
//           yr: "upper",
//           name: "Off-Campus",
//           creds: 35,
//           flex: 375,
//           guest: 2,
//           cost: 1033,
//         },
//       ]

export {
  BREAKFAST_AYCE_COST,
  DINNER_AYCE_COST,
  LUNCH_AYCE_COST,
  MEAL_PLANS,
  SWIPE_COST,
  TOTAL_WEEKS,
  TOTAL_DAYS,
}