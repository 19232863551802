
import { defineComponent } from "vue";
import { MEAL_PLANS, LUNCH_AYCE_COST, DINNER_AYCE_COST, BREAKFAST_AYCE_COST, SWIPE_COST } from "./constants";
import MealTable from './MealTable.vue'
import { IPlan } from "./types";

interface IState {
  BREAKFAST_AYCE_COST: number;
  LUNCH_AYCE_COST: number;
  DINNER_AYCE_COST: number;
  SWIPE_COST: number;
  ignoreFlex: boolean;
  plannedMealsPerWk: number;
  plans: IPlan[];
  rattyCost: string;
  showAdvanced: boolean;
  showExtended: boolean;
}

export default defineComponent({
  components: {
    MealTable
  },
  data(): IState {
    const comp = (a: IPlan, b: IPlan) => {
      if (a.creds < b.creds) {
        return 1;
      } else if (a.creds > b.creds) {
        return -1;
      }
      return 0;
    }
    return {
      BREAKFAST_AYCE_COST,
      LUNCH_AYCE_COST,
      DINNER_AYCE_COST,
      SWIPE_COST,
      ignoreFlex: false,
      plannedMealsPerWk: 21,
      plans: MEAL_PLANS.sort(comp),
      rattyCost: "avg",
      showAdvanced: false,
      showExtended: false,
    } 
  },
});
