<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <!-- <router-link :to="{ name: 'TrafficSimulator' }">Traffic Simulator</router-link> -->
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://github.com/KevinCox9600">Github</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.linkedin.com/in/kevindanielcox/">LinkedIn</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Projects
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!-- TODO: make these router links -->
            <a class="dropdown-item" href="https://www.burntoutatbrown.com/courses" rel="noopener" target="_blank">Burnt Out @ Brown</a>
            <a class="dropdown-item" href="/brown-meal-plans">Brown Meal Plan Calculator</a>
            <a class="dropdown-item" href="/bill-splitter">Bill Splitter</a>
            <a class="dropdown-item" href="/doomsday-trainer">Doomsday Trainer</a>
            <a class="dropdown-item" href="/asl-table">ASL signs by frequency</a>
            <!-- <div class="dropdown-divider"></div> -->
            <!-- <a class="dropdown-item" href="#">Something else here</a> -->
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
/* div {
  border-bottom: 1px solid black;
} */

/* section {
  margin: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  background-color: white;
} */
</style>