<template>
  <div class="everythingYay">
    <div id="superlative-title" class="py-3 text-center">
      <h1 class="">Kevin Cox for Senior Superlatives</h1>
    </div>
    <div class="container pt-3">
      <h2>Your Tasks</h2>
      <ol>
        <li>
          Vote Kevin Cox for <strong>"Most likely to be found sleeping in the CIT"</strong> because I'm purportedly:
          <ol>
            <li>Very often in the CIT</li>
            <li>Very often sleeping</li>
            <li>Very findable</li>
          </ol>

          Ergo, I'm a top notch candidate for most likely to be found sleeping in the CIT<br><br>


          <div class="lookAtThisItsImportant p-2 text-center">
            <span class="py-2 my-5">
              <strong :class="flashClass" class="p-1"><a class="linq" :href="formLink" target="_blank">THE FORM</a></strong><br>
              Note: I have seen nothing explicitly forbidding non-seniors from voting
            </span>
          </div>

        </li>
        <br>
        <li>
          I'm tragically not listed as a candidate for <strong>"Most likely to make flyers to win a senior superlative"</strong>,
          despite a very earnest self-nomination.
          <br>
          <br>
          However, as you have seen, I'm actually extraordinarily likely to make flyers.
          <br>
          <br>
          <div class="lookAtThisItsImportant p-2 text-center">
            <span class="py-2 my-5">
              Therefore, please use
              <br><strong :class="flashClass" class="p-1"><a class="linq" :href="emailLink" target="_blank">this template email</a></strong><br>
              to right this wrong
            </span>
          </div>
        </li>
        <br>
        <div class="text-center">
          <img class="" src="../../assets/senior-superlative-web.png">
        </div>
      </ol>
    </div>
  </div>
</template>

<script>
const timeout = 8000;

export default {
  data() {
    const toAddress = encodeURIComponent("classboard@brown.edu");
    const subject = encodeURIComponent("URGENT: Kevin Cox is a flyer-making fiend");
    // const body = encodeURIComponent('Kevin Cox should be selected for "Most likely to make flyers to try to win a senior superlative", or at the very least be included on the form for this role, as I can personally attest to him being extraordinarily likely to make flyers to win a senior superlative.\nBest,Kevin');
    const body = 'Dearest+board+of+class+coordinators%2C%0D%0A%0D%0AKevin+Cox+should+be+selected+for+%22Most+likely+to+make+flyers+to+try+to+win+a+senior+superlative%22%2C+or+at+the+very+least+be+included+on+the+form+for+this+role%2C+as+I+can+personally+attest+to+him+being+extraordinarily+likely+to+make+flyers+to+win+a+senior+superlative.%0D%0A%0D%0ABest+regards%2C%0D%0AMe';

    return {
      // emailLink: `https://mail.google.com/mail/u/0/?fs=1&to=${toAddress}&su=${subject}&body=${body}&tf=cm`,
      emailLink: `https://docs.google.com/document/d/1hRN9rNlj5-L6raBn0DlFTESCyFZtIclScLXt2X_GdIo/edit`,
      formLink: 'https://docs.google.com/forms/d/e/1FAIpQLSeZ0DgFpW0p3rnjfVB5YqZufJ0JLKrRqDf1tv3BBoCS4Bfegg/viewform',
      flashClass: 'superImportant p-1',
    };
  },
  methods: {
    flashOn() {
      this.flashClass = "superImportant p-1";
      // setTimeout(this.flashOn, timeout);
    },
    flashOff() {
      this.flashClass = "p-1";
      // setTimeout(this.flashOff, timeout);
    },
    threeFlashes() {
      const start = 5500;
      const inc = 500;
      // const pause = 5000;

      setTimeout(this.flashOff, start);
      setTimeout(this.flashOn, start + inc);
      setTimeout(this.flashOff, start + 2 * inc);
      setTimeout(this.flashOn, start + 3 * inc);
      setTimeout(this.flashOff, start + 4 * inc);
      setTimeout(this.flashOn, start + 5 * inc);
      setTimeout(this.threeFlashes, start + 5 * inc)
    }
  },
  mounted() {
    this.threeFlashes();
  },
}
// Kevin Cox should be selected for "Most likely to make flyers to try to win a senior superlative",
// or at the very least be included on the form for this role, as I can personally attest to him
// being extraordinarily likely to make flyers to win a senior superlative.
</script>

<style scoped>
img {
  max-width: 100%;
}
/* .everythingYay { */
body {
  background-color: #F0EBD8;
  color: #0D1321;
  /* color: white; */
}

#superlative-title {
  /* background-color:aquamarine; */
  background-color:#1D2D44;
  color:#F0EBD8 ;
}

.lookAtThisItsImportant {
  border: 1px solid ;
}

.superImportant {
  /* background-color:#3E5C76;  */
  background-color:aquamarine; 
}

/* .linq {
  color:#F0EBD8; 
} */
</style>