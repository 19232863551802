<template>
  <div class="">
    <TheHeader />
    <div class="row pt-5">
      <div id="about-container" class="col-6">
        <div id="bio">
          <h1 id="name-text">Hi, I'm Kevin</h1>
          <!-- <p>Learner, Developer</p> -->
          <p>
            I'm a junior at Brown who is passionate about applying technology to solve
            real-world problems.
          </p>
          <p>
            Outside of Computer Science, I love linguistics, learning languages, cycling,
            playing ultimate frisbee, and reading.
          </p>
        </div>
      </div>
      <div id="headshot-container" class="col-6 text-center">
        <img id="headshot" src="~@/assets/building_42_headshot.jpg" />
      </div>
    </div>
    <!-- <h1>About me</h1>
    <p>
      My name is Kevin Cox, and I am currently pursuing a Bachelors in Computer Science at Brown
      University. This summer I'm working as a software engineering intern at Microsoft,
      but I previously took a gap year to pursue a year-long internship at Webucator.
    </p>
    <h1>Hello. I'm Kevin</h1>
    <p>Blurb</p> -->
  </div>
</template>

<script>
import TheHeader from "./TheHeader.vue";
// import TheIntro from "./TheIntro.vue";
// import ContentSection from "./ContentSection.vue";

export default {
  name: "App",
  components: {
    // ContentSection,
    TheHeader,
    // TheIntro,
  },
  data() {
    return {
      // titles: ["About"],
    };
  },
};
</script>

<style>
#bio {
  width: 75%;
}

#headshot {
  max-width: 80%;
  max-height: 88%;
}

#about-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#name-text {
  font-size: 75px;
}
</style>