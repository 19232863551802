
import { round } from "../../helpers";
import { IPlan } from "./types";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import {
  BREAKFAST_AYCE_COST,
  DINNER_AYCE_COST,
  LUNCH_AYCE_COST,
  SWIPE_COST,
  TOTAL_DAYS,
  TOTAL_WEEKS,
} from "./constants";

export default defineComponent({
  props: {
    ignoreFlex: {
      type: Boolean,
      required: false,
      default: true,
    },
    plannedMealsPerWk: {
      type: Number,
      required: false,
      default: 21,
    },
    plans: {
      type: Object as PropType<IPlan[]>,
      required: true,
    },
    rattyCost: {
      type: String,
      required: false,
    }
  },
  methods: {
    calculateMealCost(): void {
      for (const plan of this.plans) {
        const totalMeals = this.totalMeals(plan);
        const cost = this.ignoreFlex ? plan.cost - plan.flex : plan.cost;
        plan.mealCost = this.round(cost / totalMeals, 2);
        plan.totalMeals = totalMeals;
      }
    },
    mealsPerDay(plan: IPlan): number {
      return this.totalMeals(plan) / TOTAL_DAYS;
    },
    rattyProp(plan: IPlan): number {
      const mealsPerWk = this.mealsPerDay(plan) * 7;
      // console.log(plan)
      const cost = this.ignoreFlex ? plan.cost - plan.flex : plan.cost;
      const planCostPerWk = cost / TOTAL_WEEKS;
      // console.log("mpw, costpw", mealsPerWk, planCostPerWk);

      for (let rattyPerWk = 0; rattyPerWk <= 21; rattyPerWk += 0.1) {
        let costOfRatty = 0
        let costs = [];
        if (this.rattyCost === 'avg') {
          let rc = 1/3 * ( DINNER_AYCE_COST + LUNCH_AYCE_COST + BREAKFAST_AYCE_COST );
          costs = [rc, rc, rc];
        } else {
          costs = [DINNER_AYCE_COST, LUNCH_AYCE_COST, BREAKFAST_AYCE_COST];
        }

        let tmpRattyPerWk = rattyPerWk;
        for (let mealCost of costs) {
          costOfRatty += mealCost * Math.max(0, Math.min(7, tmpRattyPerWk));
          tmpRattyPerWk -= 7;
        }

        if (costOfRatty + (mealsPerWk - rattyPerWk) * SWIPE_COST >= planCostPerWk) {
          return rattyPerWk / mealsPerWk * 100;
        }
      }

      return -1;
    },
    round,
    totalMeals(plan: IPlan) {
      let totalMeals: number;

      if (this.ignoreFlex) {
        totalMeals = plan.creds + plan.guest;
      } else {
        totalMeals = plan.creds + plan.flex / SWIPE_COST + plan.guest;
      }
      
      return Math.min(totalMeals, this.plannedMealsPerWk * TOTAL_WEEKS);
    }
  },
  mounted() {
    this.calculateMealCost();
  },
  watch: {
    ignoreFlex() {
      this.calculateMealCost();
    },
    plannedMealsPerWk() {
      this.calculateMealCost();
    },
  }
});
