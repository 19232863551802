<template>
  <div class="container">
    <div class="text-center pt-4">
      <h1><a href="https://dining.brown.edu/meal-plans/#meal-plan-options" rel="noopener" target="_blank">Brown Meal Plans</a></h1>
      <h2>Are they worth it? Probably not :(</h2>
    </div>

    <h2>Meal Plans</h2>
    <p>
      This site was created to increase transparency about Brown meal plans and to help in deciding if
      they are worth it for you.
    </p>
    <p>
      Edit 08/08/23: Add settings to account for missed meals and to ignore flex points
      (because most people use flex points at e.g. the blue room and not the ratty/vdub, which is equivalent to bear
      buck use and can therefore be ignored; shows Flex 70 is thus a horrendous deal)
    </p>
    <div id="how-to">
      <h2>How to use</h2>
      <ol>
        <li>Find a meal plan that gives you enough swipes per week</li>
        <li>Look at the ratty ratio</li>
        <li>
          If you do not go to the ratty or vdub at least that percent of the time,
          you will save money buying dining hall meals with bear bucks instead of being on meal plan!

          <ul>
            <li>
              If the ratio is above 1, then you're likely being scammed by Brown—you still lose money even if all your swipes are at the ratty/vdub!
            </li>
            <li>
              Note: the default calculation assumes you are equally likely to get ratty breakfast as dinner.
              However, if you will only go to the ratty for the most expensive mealtimes (i.e. dinner),
              use the max cost option for ratty cost in advanced settings
            </li>
          </ul>
        </li>
        <li>
          If the cost per meal is more than your favorite Thayer restaurant, then you'll save
          money eating on Thayer every day.
        </li>
      </ol>
    </div>

    <!-- Advanced Settings -->
    <div class="text-center pb-4">
      <button @click="showAdvanced = !showAdvanced" class="btn btn-secondary">{{ showAdvanced ? "Hide" : "Show" }} Advanced Settings</button>

      <div v-if="showAdvanced" class="col pt-2 advanced-options">
        <div class="">
          <label for="ratty-cost-selector" class="pr-1">Ratty Cost Calculation (see ratty ratio section in more calculation details for further info):</label>
          <select id="ratty-cost-selector" v-model="rattyCost">
            <option value="avg">Average cost</option>
            <option value="max">Max cost</option>
          </select>
        </div>
        <div class="pt-4">
          <input id="ignore-flex" type="checkbox" v-model="ignoreFlex" />
          <label for="ignore-flex" class="pl-1">Do not use flex credits as meal swipes</label>
        </div>
        <div class="pt-2 pb-2">
          <!-- <label for="meals-per-wk" class="pr-1">Meals per week at dining halls:</label> -->
          Account for missed meals: I plan to eat only
          <span class="px-1"><input type="number" min="0" max="21" v-model="plannedMealsPerWk" /></span>
          meals per week at dining halls
        </div>
      </div>
    </div>

    <MealTable :plans="plans" :rattyCost="rattyCost" :ignoreFlex="ignoreFlex" :plannedMealsPerWk="plannedMealsPerWk"/>
    <!-- Commentary -->
    <div>
      <h2>My Commentary &#128579;</h2>
      <ul>
        <li>
          Calculations assume you use every single meal, including guest swipes.
          If you don't, you're getting an even worse deal.
          Experiment with this in advanced settings!
        </li>
        <li>You must go to the ratty/vdub with non-negligible frequency for meal plans to be worth it (peep the ratty ratio!)</li>
        <li>
          Paying for all dining hall meals with bear bucks (instead of meal plan) is a highly underrated option.
          Bear bucks costs are:
          <ul>
            <li>Normal swipe (andrews/jo's): ${{ SWIPE_COST }}</li>
            <li>All you can eat swipes (ratty/vdub): ${{ BREAKFAST_AYCE_COST }} (breakfast), ${{ LUNCH_AYCE_COST }} (lunch), and ${{ DINNER_AYCE_COST }} (dinner)</li>
          </ul>
        </li>
        <li>
          If you're okay avoiding the ratty/vdub, you will save obscene amounts of money &#129297; &#129297; &#129297; by
          paying with bear bucks, poaching swipes off of friends, and even eating out
          on Thayer at cheaper places like Chipotle! And you still don't even have to cook a
          single meal the whole semester!
        </li>
      </ul>
    </div>
    <button class="btn btn-danger" @click="showExtended = !showExtended">{{ showExtended ? 'Hide' : 'Show' }} More Calculation Details</button>
    <div v-if="showExtended" class="pt-2">
      <h2>Calculation Details</h2>
      <ul>
        <li>Meals per wk = meal credits + guest credits + (flex points / cost per swipe)</li>
        <li>Cost per meal = cost per semester / (meals per wk * 15 wks)</li>
        <li>
          Ratty ratio solves for rr in: rr * ratty cost + (1 - rr) * 11.25 = meal cost
          <ul>
            <li>Average (default): use ratty cost = avg(ratty breakfast + ratty lunch + ratty dinner)</li>
            <li>
              Max: iterates through 0 to 20 meals at ratty per week, calculating the ratty cost by assuming the first
              7 meals to be ratty dinners, then the next 7 to be lunches, then the next to be breakfasts.
              Terminates once we break even. An iterative solution instead of numerical because
              the ratty cost changes in a piecewise manner.
            </li>
          </ul>
        </li>
        <li>
          Planned meals per week setting: limits the meals per week, keeping the same overall price
          but changing the cost per meal and ratty ratio.
        </li>
      </ul>
      <h2>Cost of swipes (2023-2024)</h2>
      <ul>
        <li>Retail eateries (andrews, jo's, etc.): $11.25</li>
        <li>
          All you can eat eateries (ratty, vdub):
          <ul>
            <li>Swipe with flex points: ${{ SWIPE_COST }}</li>
            <li>Breakfast with bear bucks: ${{ BREAKFAST_AYCE_COST }}</li>
            <li>Lunch with bear bucks: ${{ LUNCH_AYCE_COST }}</li>
            <li>Dinner with bear bucks: ${{ DINNER_AYCE_COST }}</li>
          </ul>
        </li>
      </ul>
      <h3>Additional Notes</h3>
      <ul>
        <li>
          Flex meal plans may be more worth it than the calculations show if many of your meals
          do not cost a full swipe
        </li>
        <li>
          You can purchase additional flex points through
          <a href="https://mycard.brown.edu/" rel="noopener" target="_blank">my card</a>
          when on meal plan. This means additional
          ratty/vdub swipes can be purchased for only $11.25
        </li>
        <li>
          Let me know <a href="https://forms.gle/EZZMSx3SBMaBqbjf7" rel="noopener" target="_blank">here</a>
          if you have any comments or feedback!
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MEAL_PLANS, LUNCH_AYCE_COST, DINNER_AYCE_COST, BREAKFAST_AYCE_COST, SWIPE_COST } from "./constants";
import MealTable from './MealTable.vue'
import { IPlan } from "./types";

interface IState {
  BREAKFAST_AYCE_COST: number;
  LUNCH_AYCE_COST: number;
  DINNER_AYCE_COST: number;
  SWIPE_COST: number;
  ignoreFlex: boolean;
  plannedMealsPerWk: number;
  plans: IPlan[];
  rattyCost: string;
  showAdvanced: boolean;
  showExtended: boolean;
}

export default defineComponent({
  components: {
    MealTable
  },
  data(): IState {
    const comp = (a: IPlan, b: IPlan) => {
      if (a.creds < b.creds) {
        return 1;
      } else if (a.creds > b.creds) {
        return -1;
      }
      return 0;
    }
    return {
      BREAKFAST_AYCE_COST,
      LUNCH_AYCE_COST,
      DINNER_AYCE_COST,
      SWIPE_COST,
      ignoreFlex: false,
      plannedMealsPerWk: 21,
      plans: MEAL_PLANS.sort(comp),
      rattyCost: "avg",
      showAdvanced: false,
      showExtended: false,
    } 
  },
});
</script>

<style>
.advanced-options {
  border: 1px solid black;
  border-radius: 5px;
}
</style>