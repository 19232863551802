
import { defineComponent } from "vue";

interface IState {
  page: string;
  total: number | undefined;
  names: string;
  namesList: string[];
  items: IItem[];
  currentId: number;
  people: IPerson[];
}

interface IItem {
  id: number;
  name: string;
  cost: number | undefined;
  owners: { [key: string]: boolean }; // list of name to boolean: { 'joe': true, 'mama': false}
}

interface IPerson {
  name: string;
  items?: IItem[];
  rawDebt: number;
  trueDebt?: number;
}

export default defineComponent({
  data(): IState {
    return {
      page: "introInfo",
      // total: 42,
      total: undefined,
      // names: "alpha, bravo, charlie",
      names: "",
      namesList: [],
      items: [],
      currentId: 0,
      people: [],
    };
  },
  methods: {
    /** Adds a new empty item to items */
    addItem(): void {
      this.items.push({
        id: this.currentId,
        name: "",
        cost: undefined,
        owners: {},
      });
      this.currentId++;
    },
    /** Stores true debts in people objects */
    calculateTrueDebts(): void {
      const totalDebt: number = this.people.reduce((acc, person) => {
        return acc + person.rawDebt;
      }, 0);
      const multiplier = this.total! / totalDebt;
      this.people = this.people.map((person) => {
        return { ...person, trueDebt: this.round(person.rawDebt * multiplier) };
      });
    },
    /** Describes the cost of the item and how many it was split amongst */
    describeItem(item: IItem): string {
      const owners = Object.keys(item.owners).filter(
        (owner) => item.owners[owner]
      );

      // build result string to be of form: "(itemName: cost[ / numSplitters]?)"
      let result = `${item.name || "Unnamed"}: `;
      result += `$${item.cost!}`;
      if (owners.length !== 1) {
        result += ` / ${owners.length}`;
      }
      return `(${result})`;
    },
    /** Describes all the items in a list */
    describeItems(items: IItem[] | undefined): string {
      if (items === undefined) {
        return "";
      }
      const itemDescriptions: string[] = [];
      for (const item of items) {
        itemDescriptions.push(this.describeItem(item));
      }
      return `Subtotal Items: ${itemDescriptions.join(" + ")}`;
    },
    /** Duplicates an item with a reset list of owners */
    duplicateItem(item: IItem): void {
      const dup: IItem = { ...item, owners: {} };
      this.items.push(dup);
    },
    /** Goes back from results page to items page without resetting data */
    goBack(page: string) {
      this.page = page;
    },
    /** Removes the given item from the list of items */
    removeItem(item: IItem): void {
      this.items = this.items.filter((i) => i !== item);
    },
    /** Resets the inputted information */
    reset(): void {
      this.total = undefined;
      this.names = "";
      this.items = [];
      this.page = "introInfo";
    },
    /** Rounds a number to a given number of decimal places */
    round(num: number, decimalPlaces = 2): number {
      const powerOfTen = Math.pow(10, decimalPlaces);
      return Math.round(num * powerOfTen) / powerOfTen;
    },
    /** Selects or deselects all people */
    selectAll(): void {
      // for (const person in this.people) {
      //   person.
      // }
    },
    /** Submits the total cost and names and moves to itemization */
    submitIntroInfo(): void {
      this.namesList = this.names
        .split(/[^a-zA-Z0-9\-_' ]+/)
        .filter((w) => w !== "")
        .map((w) => w.trim());
      this.namesList = [...new Set(this.namesList)];
      if (this.items.length == 0) {
        this.addItem();
      }
      this.page = "items";
    },
    /** Submits itemized costs and calculates everyone's money owed */
    submitItems(): void {
      // initialize people object
      interface IPeople {
        [key: string]: IPerson;
      }
      const peopleObject: IPeople = {};
      this.namesList.forEach((name) => {
        peopleObject[name] = { name, rawDebt: 0 };
      });

      // add cost of each item to people's debts
      this.items.forEach((item: IItem) => {
        const itemOwnerNames = Object.keys(item.owners).filter(
          (name) => item.owners![name]
        );
        const costPerPerson = item.cost! / itemOwnerNames.length;
        itemOwnerNames.forEach((name) => {
          peopleObject[name].rawDebt += costPerPerson;
          // push item onto person's items list
          peopleObject[name].items = peopleObject[name].items ?? [];
          peopleObject[name].items!.push(item);
        });
      });

      // convert people object to people list
      this.people = [];
      Object.keys(peopleObject).forEach((name) =>
        this.people.push({ ...peopleObject[name] })
      );
      this.calculateTrueDebts();

      // show summary page
      this.page = "summary";
    },
    venmoLink(person: IPerson): string {
      const note = encodeURIComponent(
        this.describeItems(person.items)
        // + "\nautogenerated by https://kevincox9600.github.io/bill-splitter"
      );
      return (
        `https://venmo.com/?` +
        `txn=charge&` +
        `audience=private&` +
        `amount=${person.trueDebt}&` +
        `note=${note}`
      );
    },
  },
});
