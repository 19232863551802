<template>
  <div class="container">
    <h1>ASL signs ordered by frequency</h1>
    <table class="table table-responsive">
      <thead>
        <th>Sign Frequency</th>
        <th>Lemma</th>
        <!-- <th>English Word Frequency (log 10)</th> -->
        <th>Links</th>
        <th>English Translations</th>
      </thead>
      <tbody>
        <tr v-for="row in data" :key="row.lemma">
          <td>{{ row.signFrequency }}</td>
          <td>
            {{ row.text }}
          </td>
          <td class="w-50">
            <a :href="`https://aslsignbank.haskins.yale.edu/dictionary/gloss/${row.id}.html`" target="_blank" rel="noopener noreferrer">Sign Bank</a>,
            <a :href="`https://www.youtube.com/channel/UCZy9xs6Tn9vWqN_5l0EEIZA/search?query=${row.text}`" target="_blank" rel="noopener noreferrer">Lifeprint youtube</a>,
            <a :href="`https://www.lifeprint.com/asl101/pages-signs/${row.withoutSuffix && row.withoutSuffix[0]}/${row.withoutSuffix}.htm`" target="_blank" rel="noopener noreferrer">Lifeprint site</a>
          </td>
          <!-- <td>{{ row.englishWordFrequency }}</td> -->
          <td>{{ row.englishTranslations }}</td>
        </tr>
      </tbody>
    </table>
    <p>
      Data from ASL-LEX:
    </p>
    <ul>
      <li>
        Sevcikova Sehyr, Z., Caselli, N., Cohen-Goldberg, A. M., & Emmorey, K. (2021). The ASL-LEX 2.0 Project: A database of lexical and phonological properties for 2,723 signs in American Sign Language. Journal of Deaf Studies and Deaf Education. doi.org/10.1093/deafed/enaa038.
      </li>
      <li>
        Caselli, N., Sevcikova Sehyr, Z., Cohen-Goldberg, A. M., & Emmorey, K. (2017). ASL-LEX: A lexical database of American Sign Language. Behavior Research Methods, 49(2), 784-801. doi:10.3758/s13428-016-0742-0.
      </li>
    </ul>
  </div>
</template>

<script>
import * as data from "../../data/aslData.json";
export default {
  data() {
    return {
      data: data.default,
    };
  },
  mounted() {
    if (!this.data) {
      console.log("oop");
    }
    for (const row of this.data) {
      row.withoutSuffix = row.lemma.replace(/_\d/g, "");
      row.text = row.withoutSuffix.replace(/_/g, " ");
    }
    console.log(this.data);
  },
};
</script>

<style>
</style>